import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const postFormData = createAsyncThunk(
  "formData/post",
  async (formData, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        "https://nilecruisesapi.titegypt.org/api/order/CreateOrderPaymentStatus",
        // "https://nilecruisesapi.titegypt.org/api/order/CreateOrdernew",
        // "https://nilecruisesapi.titegypt.org/api/order/CreateOrder",
        formData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
const customerDataSlice = createSlice({
  name: "customerData",
  initialState: {
    customerData: "",
    loadings: true,
    errors: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(postFormData.pending, (state) => {
        state.loadings = true;
      })
      .addCase(postFormData.fulfilled, (state, action) => {
        state.loadings = false;

        state.customerData = action.payload;
      })
      .addCase(postFormData.rejected, (state, action) => {
        state.loadings = false;
        state.errors = action.error.message;
      });
  },
});

export default customerDataSlice.reducer;
