import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Url } from "../url";

export const fetchNationality = createAsyncThunk(
  "nationality/fetchNationality",
  async (language) => {
    const response = await fetch(`${Url}/api/nationality/getnatioNalities`);
    const nationalityData = await response.json();
    return nationalityData;
  }
);

const nationalitySlice = createSlice({
  name: "nationality",
  initialState: {
    nationality: [],
    loading: true, // 'idle' | 'loading' | 'succeeded' | 'failed'
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchNationality.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchNationality.fulfilled, (state, action) => {
        state.loading = false;
        // Add any fetched dining data to the array
        state.nationality = action.payload;
      })
      .addCase(fetchNationality.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default nationalitySlice.reducer;
