import { useEffect } from "react";

const useGoogleMapsScript = (apiKey) => {
  useEffect(() => {
    const existingScript = document.getElementById("googleMapsScript");

    if (!existingScript) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}`;
      script.id = "googleMapsScript";
      document.body.appendChild(script);

      script.onload = () => {
        console.log("Google Maps script loaded!");
      };
    }
  }, [apiKey]);
};

export default useGoogleMapsScript;
