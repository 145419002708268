import React, { useRef, useState } from "react";
import { Fade, Zoom } from "react-awesome-reveal";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
// import { BookingForm } from "../components/BookingForm";
// import { BookingFormGroup } from "../components/cruiseBooking/BookingFormGroup";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation, Autoplay } from "swiper/modules";
// import "swiper/swiper-bundle.min.css";
import { DateRangePicker } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import { format } from "date-fns";
const Home = () => {
  const navigate = useNavigate();
  const locations = [
    { name: "London", description: "Greater London, United Kingdom" },
    { name: "New York", description: "New York State, United States" },
    { name: "Paris", description: "France" },
    { name: "Madrid", description: "Spain" },
    { name: "Santorini", description: "Greece" },
    { name: "Egypt", description: "Cairo, Egypt" },
  ];
  const [isActiveDestination, setIsActiveDestination] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const searchMenuRef = useRef(null);

  // Toggle the active state on click
  const handleDestinationClick = () => {
    setIsActiveDestination(!isActiveDestination);
  };

  // Handle blur event
  const handleBlur = (event) => {
    if (
      searchMenuRef.current &&
      !searchMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveDestination(false);
    }
  };

  // Handle selection of a location
  const handleLocationSelect = (location) => {
    setSelectedLocation(location.name); // Set the selected location
    setSearchTerm(""); // Clear the search term
    setIsActiveDestination(false); // Close the dropdown after selection
  };

  // Filter locations based on search term
  const filteredLocations = locations.filter((location) =>
    location.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const [value, setValue] = useState([null, null]);

  const handleChange = (dates) => {
    setValue(dates);
  };

  const [adults, setAdults] = useState(2);
  const [children, setChildren] = useState(1);
  const [rooms, setRooms] = useState(1);

  const handleIncrement = (setter) => {
    setter((prev) => prev + 1);
  };

  const handleDecrement = (setter) => {
    setter((prev) => (prev > 0 ? prev - 1 : 0));
  };

  const [isActiveGuest, setIsActiveGuest] = useState(false);

  const GuestMenuRef = useRef(null);

  // Toggle the active state on click
  const handleGuestClick = () => {
    setIsActiveGuest(!isActiveGuest);
  };
  const handleGuestBlur = (event) => {
    if (
      GuestMenuRef.current &&
      !GuestMenuRef.current.contains(event.relatedTarget)
    ) {
      setIsActiveGuest(false);
    }
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(date, "EEE d MMM"); // e.g., "Wed 2 Mar"
  };
  const disablePastDates = (date) => {
    const today = new Date();
    return date < today.setHours(0, 0, 0, 0); // Disable past dates
  };
  return (
    <>
      {" "}
      <Helmet>
        <title>Booking Nile</title>
      </Helmet>
      {/*banner */}
      <section className="masthead -type-1 z-5 ">
        <div className="masthead__bg is-in-view">
          <img src="bg.jpg" alt="bg" className="js-lazy loaded  water-effect" />
        </div>

        <div className="container">
          <div className="row justify-center">
            <div className="col-auto">
              <div className="text-center water-text">
                <h1 className="text-60 lg:text-40 md:text-30 text-white is-in-view  ">
                  Discover Your Next Nile Adventure
                </h1>
                <p className="text-white mt-15  is-in-view">
                  Explore breathtaking destinations with exclusive Nile Star
                  deals!
                </p>
              </div>

              <div className="tabs -underline mt-60 js-tabs is-in-view">
                {/*    <div className="tabs__controls d-flex x-gap-30 y-gap-20 justify-center sm:justify-start js-tabs-controls">
                 <div className="">
                    <button
                      className="tabs__button text-15 fw-500 text-white pb-4 js-tabs-button is-tab-el-active"
                      data-tab-target=".-tab-item-1"
                    >
                      Cruise
                    </button>
                  </div> 
                </div>*/}

                <div className="tabs__content mt-30 md:mt-20 js-tabs-content">
                  <div className="tabs__pane -tab-item-1 is-tab-el-active">
                    <div className="mainSearch -w-1000 bg-white px-10 py-10 lg:px-20 lg:pt-5 lg:pb-20 rounded-100">
                      <div className="button-grid items-center">
                        <div
                          ref={searchMenuRef}
                          className={`searchMenu-loc px-30 lg:py-20 lg:px-0 js-form-dd js-liverSearch  ${
                            isActiveDestination ? "-is-dd-wrap-active" : ""
                          }`}
                          onClick={handleDestinationClick}
                        >
                          <div
                            onBlur={handleBlur}
                            data-x-dd-click="searchMenu-loc"
                          >
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Location
                            </h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <input
                                autoComplete="off"
                                type="search"
                                placeholder="Where are you going?"
                                className="js-search js-dd-focus"
                                value={selectedLocation} // Set the input value to the selected location
                                onChange={(e) => {
                                  setSelectedLocation(e.target.value);
                                  setSearchTerm(e.target.value); // Update search term to filter results
                                }}
                              />
                            </div>
                          </div>

                          <div
                            className={`searchMenu-loc__field shadow-2 js-popup-window ${
                              isActiveDestination ? "-is-active" : ""
                            }`}
                            data-x-dd="searchMenu-loc"
                            data-x-dd-toggle="-is-active"
                          >
                            <div className="bg-white px-30 py-30 sm:px-0 sm:py-15 rounded-4">
                              <div className="y-gap-5 js-results">
                                {filteredLocations.length > 0 ? (
                                  filteredLocations.map((location) => (
                                    <div key={location.name}>
                                      <button
                                        className="-link d-block col-12 text-left rounded-4 px-20 py-15 js-search-option"
                                        onClick={() =>
                                          handleLocationSelect(location)
                                        }
                                      >
                                        <div className="d-flex">
                                          <div className="icon-location-2 text-light-1 text-20 pt-4"></div>
                                          <div className="ml-10">
                                            <div className="text-15 lh-12 fw-500">
                                              {location.name}
                                            </div>
                                            <div className="text-14 lh-12 text-light-1 mt-5">
                                              {location.description}
                                            </div>
                                          </div>
                                        </div>
                                      </button>
                                    </div>
                                  ))
                                ) : (
                                  <div className="text-center py-15">
                                    No results found
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="searchMenu-date px-10 lg:py-20 lg:px-0 -is-dd-wrap-active">
                          <div>
                            <h4 className="text-15 fw-500 ls-2 lh-16">
                              Check in - Check out
                            </h4>
                            <DateRangePicker
                              value={value}
                              onChange={handleChange}
                              placeholder="Select Date Range"
                              className="text-15 text-light-1 ls-2 lh-16"
                              disabledDate={disablePastDates} // Add this prop to disable past dates
                              renderValue={(dates) => {
                                if (!dates[0] || !dates[1])
                                  return "Select Date Range";
                                return `${formatDate(dates[0])} - ${formatDate(
                                  dates[1]
                                )}`;
                              }}
                            />
                          </div>
                        </div>

                        <div
                          ref={GuestMenuRef}
                          className={`searchMenu-guests px-30 lg:py-20 lg:px-0 js-form-dd js-form-counters ${
                            isActiveGuest ? "-is-dd-wrap-active" : ""
                          }`}
                          onBlur={handleGuestBlur}
                        >
                          <div
                            data-x-dd-click="searchMenu-guests"
                            onClick={handleGuestClick}
                          >
                            <h4 className="text-15 fw-500 ls-2 lh-16">Guest</h4>
                            <div className="text-15 text-light-1 ls-2 lh-16">
                              <span className="js-count-adult">{adults}</span>{" "}
                              adults -
                              <span className="js-count-child">{children}</span>{" "}
                              children -
                              <span className="js-count-room">{rooms}</span>{" "}
                              room{rooms > 1 ? "s" : ""}
                            </div>
                          </div>

                          <div
                            className={`searchMenu-guests__field shadow-2  ${
                              isActiveGuest ? "-is-active" : ""
                            }`}
                          >
                            <div className="bg-white px-30 py-30 rounded-4">
                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Adults</div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() => handleDecrement(setAdults)}
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{adults}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() => handleIncrement(setAdults)}
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 lh-12 fw-500">
                                    Children
                                  </div>
                                  <div className="text-14 lh-12 text-light-1 mt-5">
                                    Ages 0 - 17
                                  </div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() =>
                                        handleDecrement(setChildren)
                                      }
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{children}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() =>
                                        handleIncrement(setChildren)
                                      }
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>

                              <div className="border-top-light mt-24 mb-24"></div>

                              <div className="row y-gap-10 justify-between items-center">
                                <div className="col-auto">
                                  <div className="text-15 fw-500">Rooms</div>
                                </div>

                                <div className="col-auto">
                                  <div className="d-flex items-center js-counter">
                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-down"
                                      onClick={() => handleDecrement(setRooms)}
                                    >
                                      <i className="icon-minus text-12"></i>
                                    </button>

                                    <div className="flex-center size-20 ml-15 mr-15">
                                      <div className="text-15">{rooms}</div>
                                    </div>

                                    <button
                                      className="button -outline-blue-1 text-blue-1 size-38 rounded-4 js-up"
                                      onClick={() => handleIncrement(setRooms)}
                                    >
                                      <i className="icon-plus text-12"></i>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="button-item">
                          <button
                            className="mainSearch__submit button -dark-1 h-60 px-35 col-12 rounded-100 bg-blue-1 text-white"
                            onClick={() => navigate("/booking-cruises")}
                          >
                            <i className="icon-search text-20 mr-10"></i>
                            Search
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="masthead -type-9">
          <Link to="#secondSection" className="masthead__scroll">
            <div className="d-flex items-center">
              <div className="text-white lh-15 text-right mr-10">
                <div className="fw-500">Scroll Down </div>
                <div className="text-15">to discover more </div>
              </div>

              <div className="-icon">
                <div></div>
                <div></div>
              </div>
            </div>
          </Link>
        </div>
      </section>
      {/*Popular Destinations */}
      <section
        className="layout-pt-lg layout-pb-md relative z--1"
        id="secondSection"
      >
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Top Nile Destinations</h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  Explore the wonders of the Nile and beyond for an
                  unforgettable journey!
                </p>
              </div>
            </div>
          </div>

          <div className="row x-gap-10 y-gap-10 pt-40 sm:pt-20">
            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">New York </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">London </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Los Angeles </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Paris </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Istanbul </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Rome </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>

            <div className="col-xl col-lg-3 col-6">
              <Link
                to="#."
                className="citiesCard -type-5 d-flex items-center sm:flex-column sm:items-start px-20 py-20 sm:px-15 sm:py-20 bg-light-2 rounded-4"
              >
                <i className="icon-destination text-24"></i>

                <div className="ml-10 sm:ml-0 sm:mt-10">
                  <h4 className="text-16 fw-500">Madrid </h4>
                  <p className="text-14">4,090 properties </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      {/* Featured Cruise Deals */}
      <section className="layout-pt-md layout-pb-lg">
        <div className="container">
          <div className="row y-gap-20 justify-center text-center">
            <div className="col-auto">
              <div className="sectionTitle -md">
                <h2 className="sectionTitle__title">Featured Cruise Deals</h2>
                <p className="sectionTitle__text mt-5 sm:mt-0">
                  Interdum et malesuada fames __ ante ipsum
                </p>
              </div>
            </div>
          </div>

          <div className="row y-gap-30 pt-40 sm:pt-20">
            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/1.png"
                        alt=""
                      />
                    </div>
                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>
                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-dark-1 text-white">
                        Cruise only
                      </div>
                    </div>
                  </div>
                </div>
                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getawayy
                  </div>
                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>7 Night Cruise to ___ Western Mediterranean</span>
                  </h4>
                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date
                      </div>
                      <div className="text-14 text-light-1">18/06/2022</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs</div>
                      <div className="text-14 text-light-1">Southampton</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7)
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...
                      </div>
                    </div>
                  </div>
                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>
                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82
                          </span>{" "}
                          94 reviews
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From{" "}
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <Swiper
                        modules={[Pagination, Navigation, Autoplay]}
                        autoplay={{
                          delay: 3000,
                          loop: false,
                          delayTimeout: 1000,
                          disableOnInteraction: false, // Continue autoplay after user interactions
                        }}
                        spaceBetween={50}
                        slidesPerView={1}
                        navigation={{
                          prevEl: ".js-prev", // Custom previous button class
                          nextEl: ".js-next", // Custom next button class
                        }} // Enable custom navigation
                        pagination={{
                          clickable: true,
                          el: ".js-pagination", // Target custom pagination class
                          renderBullet: (index, className) => {
                            return `<div className="pagination__item ${className}" tabindex="0" role="button" aria-label="Go to slide ${
                              index + 1
                            }"></div>`;
                          },
                        }}
                      >
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/1.png"
                            alt="image"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/2.png"
                            alt="image"
                          />
                        </SwiperSlide>
                        <SwiperSlide>
                          <img
                            className="col-12"
                            src="/titsolutions/img/cruises/3.png"
                            alt="image"
                          />
                        </SwiperSlide>
                      </Swiper>
                      {/* Custom Pagination Element */}
                      <div className="cardImage-slider__pagination js-pagination swiper-pagination-clickable swiper-pagination-bullets swiper-pagination-horizontal"></div>

                      {/* Custom Navigation Buttons */}
                      <div className="cardImage-slider__nav -prev">
                        <button
                          className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-prev"
                          tabindex="0"
                          aria-label="Previous slide"
                        >
                          <i className="icon-chevron-left text-10"></i>
                        </button>
                      </div>
                      <div className="cardImage-slider__nav -next">
                        <button
                          className="button -blue-1 bg-white size-30 rounded-full shadow-2 js-next"
                          tabindex="0"
                          aria-label="Next slide"
                        >
                          <i className="icon-chevron-right text-10"></i>
                        </button>
                      </div>
                    </div>
                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getaway
                  </div>
                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      12 Night Cruise to ___ British Isles & Western ______
                    </span>
                  </h4>
                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date
                      </div>
                      <div className="text-14 text-light-1">18/06/2022</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs</div>
                      <div className="text-14 text-light-1">Southampton</div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7)
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...
                      </div>
                    </div>
                  </div>
                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>
                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82
                          </span>{" "}
                          94 reviews
                        </div>
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From{" "}
                        <span className="text-16 fw-500 text-dark-1">
                          US$72
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4 ">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/3.png"
                        alt="image"
                      />
                    </div>

                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-blue-1 text-white">
                        Best Seller
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getaway{" "}
                  </div>

                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>11 Night Cruise to ___ Eastern Mediterranean </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5" />

                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date{" "}
                      </div>
                      <div className="text-14 text-light-1">18/06/2022 </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs </div>
                      <div className="text-14 text-light-1">Southampton </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7){" "}
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>

                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82{" "}
                          </span>
                          94 reviews
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>

            <div className="col-lg-3 col-sm-6">
              <Link to="#." className="cruiseCard -type-1 rounded-4 ">
                <div className="cruiseCard__image">
                  <div className="cardImage ratio ratio-6:5">
                    <div className="cardImage__content">
                      <img
                        className="rounded-4 col-12"
                        src="/titsolutions/img/cruises/4.png"
                        alt="image"
                      />
                    </div>

                    <div className="cardImage__wishlist">
                      <button className="button -blue-1 bg-white size-30 rounded-full shadow-2">
                        <i className="icon-heart text-12"></i>
                      </button>
                    </div>

                    <div className="cardImage__leftBadge">
                      <div className="py-5 px-15 rounded-right-4 text-12 lh-16 fw-500 uppercase bg-yellow-1 text-dark-1">
                        Top Rated
                      </div>
                    </div>
                  </div>
                </div>

                <div className="cruiseCard__content mt-10">
                  <div className="text-14 lh-14 text-light-1 mb-5">
                    Norwegian Getawayy{" "}
                  </div>

                  <h4 className="cruiseCard__title text-dark-1 text-18 lh-16 fw-500">
                    <span>
                      13 Night Cruise to ___ Baltic Sea Western Europe{" "}
                    </span>
                  </h4>

                  <p className="text-light-1 lh-14 text-14 mt-5" />

                  <div className="row y-gap-10 justify-between items-center">
                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Sailing Date{" "}
                      </div>
                      <div className="text-14 text-light-1">18/06/2022 </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">Departs </div>
                      <div className="text-14 text-light-1">Southampton </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-dark-1 fw-500">
                        Ports (7){" "}
                      </div>
                      <div className="text-14 text-light-1">
                        Sete, Toulon...{" "}
                      </div>
                    </div>
                  </div>

                  <div className="row y-gap-20 justify-between items-center pt-5">
                    <div className="col-auto">
                      <div className="d-flex items-center">
                        <div className="icon-star text-yellow-1 text-10 mr-5"></div>

                        <div className="text-14 text-light-1">
                          <span className="text-15 text-dark-1 fw-500">
                            4.82{" "}
                          </span>
                          94 reviews
                        </div>
                      </div>
                    </div>

                    <div className="col-auto">
                      <div className="text-14 text-light-1">
                        From
                        <span className="text-16 fw-500 text-dark-1">
                          US$72{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </section>
      <section className="section-bg layout-pt-lg layout-pb-lg">
        <div className="section-bg__item -right -w-1165 bg-light-2"></div>

        <div className="section-bg__item -video-left">
          <div className="row y-gap-30">
            <div className="col-sm-6">
              <img src="/titsolutions/img/video/1.png" alt="image" />
            </div>

            <div className="col-sm-6">
              <img src="/titsolutions/img/video/2.png" alt="image" />
            </div>
          </div>
        </div>

        <div className="container lg:mt-30">
          <div className="row">
            <div className="offset-xl-6 col-xl-5 col-lg-6">
              <h2 className="text-30 fw-600">
                Nile Star is a World-Class Cruise Booking Platform
              </h2>
              <p className="text-dark-1 mt-40 lg:mt-20 sm:mt-15">
                Experience the finest in luxury travel, with exceptional service
                and unbeatable offers that take you on a journey like no other.
                Enjoy breathtaking views and create unforgettable memories with
                us.
              </p>

              <div className="d-inline-block mt-40 lg:mt-30 sm:mt-20">
                <Link
                  to="#."
                  className="button -md -blue-1 bg-yellow-1 text-dark-1"
                >
                  Learn More <div className="icon-arrow-top-right ml-15"></div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="layout-pt-lg layout-pb-md">
        <div className="container">
          <div className="row y-gap-30">
            <div className="col-xl-4 col-lg-5">
              <h2 className="text-30 fw-600">Why Choose Us </h2>
              <p className="mt-5">
                These popular destinations have a wealth of experiences to
                offer!
              </p>

              <p className="text-dark-1 mt-40 sm:mt-20">
                Experience the ultimate in luxury travel, where every journey is
                crafted to provide you with unforgettable moments. Enjoy
                stunning views and exceptional service that make your cruise
                truly special.
              </p>

              <div className="d-inline-block mt-40 sm:mt-20">
                <Link
                  to="#."
                  className="button -md -blue-1 bg-yellow-1 text-dark-1"
                >
                  Learn More <div className="icon-arrow-top-right ml-15"></div>
                </Link>
              </div>
            </div>

            <div className="col-xl-6 offset-xl-1 col-lg-7">
              <div className="row y-gap-60">
                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/1.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/2.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/3.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                  </p>
                </div>

                <div className="col-sm-6">
                  <img
                    src="/titsolutions/img/featureIcons/3/4.svg"
                    alt="image"
                    className="size-60"
                  />
                  <h5 className="text-18 fw-500 mt-10">
                    Best Price Guarantee{" "}
                  </h5>
                  <p className="mt-10">
                    Lorem ipsum dolor sit ____, consectetur adipiscing elit.{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
