import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

const initialState = {
  extras: [],
  selectedExtra: [],
  loading: true,
  error: null,
};

// Async thunk action
export const fetchGetExtras = createAsyncThunk(
  "booking/fetchGetExtras",
  async (formData) => {
    const response = await axios.get(`${Url}/api/extra/GetExtras`, {
      params: formData,
    });
    return response.data;
  }
);

const extrasSlice = createSlice({
  name: "extras",
  initialState,

  reducers: {
    selectExtra: (state, action) => {
      const selected = state.extras.extras.find(
        (extra) => extra.extraId === action.payload.extraId
      );

      if (selected) {
        if (action.payload.checked) {
          // Add to selectedExtra array if not already present
          if (
            !state.selectedExtra.some(
              (extra) => extra.extraId === selected.extraId
            )
          ) {
            state.selectedExtra.push(selected);
          }
        } else {
          // Remove from selectedExtra array if present
          state.selectedExtra = state.selectedExtra.filter(
            (extra) => extra.extraId !== selected.extraId
          );
        }
      }
    },
    removeExtra: (state, action) => {
      // Remove the extra from selectedExtra array
      state.selectedExtra = state.selectedExtra.filter(
        (extra) => extra.extraId !== action.payload
      );
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchGetExtras.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGetExtras.fulfilled, (state, action) => {
        state.loading = false;

        state.extras = action.payload;
      })
      .addCase(fetchGetExtras.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export const { selectExtra, removeExtra } = extrasSlice.actions;
export default extrasSlice.reducer;
