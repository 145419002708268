import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import ScrollToTop from "./ScrollToTop.jsx";

import Layout from "./components/Layout";

// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import "react-image-lightbox/style.css";
import PageTracker from "./components/PageTracker.js";

import "./App.css";
import { setLanguage } from "./data/languageSlice.js";
import Home from "./pages/Home.jsx";
import ListingCruises from "./pages/ListingCruises.jsx";
import ContactUs from "./pages/ContactUs.jsx";

// import CruiseBooking from "./components/cruiseBooking/CruiseBooking.jsx";
// import CabinBooking from "./components/cruiseBooking/CabinBooking.jsx";
// import InsertCstData from "./components/cruiseBooking/InsertCstData.jsx";
// import ThanksPage from "./components/cabinsBooking/ThanksPage.jsx";
// import BookingSearch from "./pages/BookingSearch.jsx";
// import AddExtra from "./components/cruiseBooking/AddExtra.jsx";

function App() {
  const language = useSelector((state) => state.language.language);
  const [basename, setBasename] = useState("");

  const dispatch = useDispatch();
  useEffect(() => {
    const currentUrl = new URL(window.location.href);
    const pathSegments = currentUrl.pathname.split("/").filter(Boolean); // Split and remove empty segments

    if (
      pathSegments.length > 0 &&
      (pathSegments[0] === "en" || pathSegments[0] === "ar")
    ) {
      dispatch(setLanguage(pathSegments[0]));
      setBasename(pathSegments[0]);
    } else {
      window.location.href = `${currentUrl.origin}/en${currentUrl.pathname}${currentUrl.search}`;
    }
    const head = document.head;
    const existingLink = document.getElementById("rtl-stylesheet");

    if (language === "ar") {
      if (!existingLink) {
        const link = document.createElement("link");
        link.id = "rtl-stylesheet"; // Assign an ID for easy access
        link.type = "text/css";
        link.rel = "stylesheet";
        link.href = "bootstrap-rtl.min.css";
        head.appendChild(link);
      }
    } else {
      if (existingLink) {
        head.removeChild(existingLink);
      }
    }
  }, [language, dispatch, setBasename]); // Added setBasename if it's part of the state

  return (
    <HelmetProvider>
      <Router basename={basename}>
        <PageTracker />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="booking-cruises" element={<ListingCruises />} />
            <Route path="contact-us" element={<ContactUs />} />
            {/*<Route path="booking-cruises" element={<CruiseBooking />} />
            <Route path="booking-cabins" element={<CabinBooking />} />
            <Route path="add-extra" element={<AddExtra />} />
            <Route path="booking-information" element={<InsertCstData />} />
            <Route path="booking-completed" element={<ThanksPage />} />*/}
          </Route>
        </Routes>
      </Router>
    </HelmetProvider>
  );
}

export default App;
