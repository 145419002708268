import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { Url } from "../url";

const initialState = {
  confirmationBooking: [],
  loading: true,
  error: null,
};

// Async thunk action
export const fetchConfirmationBooking = createAsyncThunk(
  "booking/fetchConfirmationBooking",
  async (formData) => {
    const response = await axios.get(`${Url}/api/extra/GetExtras`, {
      params: formData,
    });
    return response.data;
  }
);

const confirmationBookingSlice = createSlice({
  name: "confirmationBooking",
  initialState,

  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchConfirmationBooking.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchConfirmationBooking.fulfilled, (state, action) => {
        state.loading = false;

        state.confirmationBooking = action.payload;
      })
      .addCase(fetchConfirmationBooking.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default confirmationBookingSlice.reducer;
